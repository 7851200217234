<template>
  <div class="my-content">
    <a-search-form
      ref="searchForm"
      v-model="form"
      :loading="loading"
      :render-data="renderData"
      @handleSearch="handleSearch"
      @handleReset="handleReset" />
    <a-tables
      :columns="columns"
      :loading="loading"
      :dataSource="data"
      :page="page"
      @onChange="onChange"
    >
    </a-tables>
  </div>
</template>
<script>
  import API from '@/api'
  export default {
    data() {
      return {
        form: {},
        loading: false,
        renderData: {
          time: {
            renderName: 'aRangePicker',
            label: '时间',
            labelCol: 7,
            wrapperCol: 17,
            span: 5,
          },
          type: {
            renderName: 'aSelect',
            label: '类型',
            options: [
              { label: '普通消息', value: 1 },
              { label: '待办消息', value: 2 },
            ]
          },
          realname: {
            label: '操作员'
          },
        },
        columns: [
          { title: '序号', dataIndex: 'index', renderType: 'index', width: 80},
          { title: '标题', dataIndex: 'module'},
          { title: '内容', dataIndex: 'content'},
          { title: '发布方', dataIndex: 'name'},
          { title: '时间', width: 180, dataIndex: 'created_at'},
        ],
        data: [],
        page: { current: 1, pageSize: 20, total: 0 },
      }
    },
    mounted() {
      this.getList()
    },
    methods: {
      async getList() {
        try {
          this.loading = true
          const params = {
            page: this.page.current,
            pagesize: this.page.pageSize,
            ...this.form
          }
          if (this.form.time) {
            params.starttime = this.form.time[0]
            params.endtime = this.form.time[1]
          }
          const { list, total } = await this.$request({
            url: API.log.logList,
            params
          })
          this.data = list
          this.page.total = total
        } finally {
          this.loading = false
        }
      },
      handleSearch() {
        this.page.current = 1
        this.getList()
      },
      handleReset() {
        this.form = {}
        this.handleSearch()
      },
      onChange(page) {
        this.page = page
        this.getList()
      },
    }
  }
</script>
